
import { useCostsActions, useCostsContext } from "@contexts/costs";
import { indexes, itemGroups } from "./components/utils";
import { useEffect, useState } from "react";

const defaultUnitValue = {
  paramId: '',
  value: 0,
  reference: '',
  date: '',
  _id: 0,
}

const UnitValues = () => {
  const { getUnitValues, updateUnitValues, getParams, deleteCostItem } = useCostsActions();
  const { currentBuilding } = useCostsContext();
  const [unitValue, setUnitValue] = useState(defaultUnitValue)
  const [unitValues, setUnitValues] = useState([])
  const [params, setParams] = useState([])

  const list = async () => {
    const res = await getParams('')
    setParams(res.res)

    const un = await getUnitValues()
    setUnitValues(un.res)
  }

  useEffect(() => {
    list()
  }, [])

  const handleSend = async () => {
    const res = await updateUnitValues(unitValue)

    if (res.status === 200) {
      setUnitValues([...unitValues, unitValue])
    }
  }

  const handleEdit = (event) => {
    event.preventDefault();

    setUnitValue(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  }

  const handleChooseUnitValue = (i) => {
    setUnitValue(params[i])
  }

  const reset = () => {
    setUnitValue(defaultParam)
  }

  const handleDelete = async (i) => {
    const res = await deleteCostItem(unitValues[i]._id, 'unitValues')

    if (res.status === 200) {
      const newItems = unitValues.filter((item, index) => index !== i)
      setUnitValues(newItems)
    }
  }

  return <div className="costs">
    <h2>Preços Unitários</h2>
    <button onClick={reset}>Novo Preço</button>
    <div className="grid columns-5-tablet gap-8 margin-bottom-16">
      <div>
        <label>Parâmetro</label>
        <select name="paramId" value={unitValue.paramId} onChange={handleEdit}>
          <option disabled value="">Selecione um parâmetro</option>
          {params.map(i => {
            return <option key={i._id}>{i.name}</option>
          })}
        </select>
      </div>
      <div>
        <label>Valor</label>
        <input type="number" onChange={handleEdit} value={unitValue.value} name="value" />
      </div>
      <div>
        <label>Referência</label>
        <input type="text" onChange={handleEdit} value={unitValue.reference} name="reference" />
      </div>
      <div>
        <label>Data</label>
        <input type="date" onChange={handleEdit} value={unitValue.date} name="date" />
      </div>
      <button onClick={handleSend} className="button button-accent">Enviar</button>
    </div>
    <div className="margin-top-16">
      {unitValues.length > 0 && unitValues.map((i, index) => {
        return <div key={i.name} className="grid columns-5-tablet gap-8 margin-bottom-4">
          <p>{i.paramId}</p>
          <p>{i.value}</p>
          <p>{i.reference}</p>
          <p>{i.date}</p>
          <div className="flex gap-4">
            <button className="button button-secondary" onClick={() => handleChooseUnitValue(index)}>Editar</button>
            <button className="button button-error" onClick={() => handleDelete(index)}>Deletar</button>

          </div>
        </div>
      })}
    </div>
  </div>
}

export default UnitValues