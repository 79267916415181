import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import Modal from "@components/Modal";
import { useBimActions, useBimContext } from "@contexts/bim";
import { translateStatus } from "@utils/translateStatus";
import Tooltip from "@components/dashboard/tooltip";
import { useAuthContext } from "@contexts/auth";

const meses = {
  "janeiro": 0,
  "fevereiro": 1,
  "março": 2,
  "abril": 3,
  "maio": 4,
  "junho": 5,
  "julho": 6,
  "agosto": 7,
  "setembro": 8,
  "outubro": 9,
  "novembro": 10,
  "dezembro": 11,
};

function convertDate(dateString) {
  // Divide a string em partes
  const split = dateString.split(" ");

  // Extrai o dia, mês, ano, hora e minuto
  const day = parseInt(split[0], 10);
  const month = meses[split[1].toLowerCase()];
  const year = parseInt(split[2], 10);
  const hour = parseInt(split[3].split(":")[0], 10);
  const mins = parseInt(split[3].split(":")[1], 10);

  // Cria um objeto Date
  const date = new Date(year, month, day, hour, mins);

  // Formata a data para o formato YYYY-MM-DD
  const formattedDate = date.toISOString().split("T")[0];

  return formattedDate;
}

const formatValue = (value) => (value ? value : "-");

const detailsFields = (handleDetailsChange, itemDetails, sendUpdate, deleteButton, confirmDelete, handleConfirmDelete) => {
  return (
    <div className="grid columns-2 gap-8">
      <div>
        <p className="accordion-label">Nome</p>
        <input onChange={handleDetailsChange} name="name" value={itemDetails.name} />
      </div>
      <div>
        <p className="accordion-label">Responsável</p>
        <input onChange={handleDetailsChange} name="owner" value={itemDetails.owner} />
      </div>
      <div>
        <p className="accordion-label">Data de Início</p>
        <input onChange={handleDetailsChange} type="date" name="startDate" value={itemDetails.startDate} />
      </div>
      <div>
        <p className="accordion-label">Data de Término</p>
        <input onChange={handleDetailsChange} type="date" name="endDate" value={itemDetails.endDate} />
      </div>
      <button className="button button-accent" onClick={sendUpdate}>Enviar</button>
      {deleteButton && <>
        {!confirmDelete ? <button className="button button-error justify-self-end" onClick={handleConfirmDelete}>DELETAR</button> : <div>
          <button className="button button-red" onClick={deleteButton}>Deletar</button>
          <button className="button button-accent" onClick={handleConfirmDelete}>Cancelar</button>
        </div >}
      </>
      }
    </div>
  );
};

const RenderItems = ({ items, level, click }) => {
  const [openItems, setOpenItems] = useState({});

  const toggleItem = (id) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const marginLeft = `${level * 20}px`;

  return items?.map((item) => (
    <div key={item._id}>
      <div
        style={{ marginLeft }}
        onClick={() => click(item)}
        className={`grid columns-5-tablet checklist-row`}
      >
        <div className="flex column-span-2">
          {item?.items?.length > 0 && <button
            className={`accordion-toggle ${openItems[item._id] ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation(); // Impede que o clique no botão acione o clique no pai
              toggleItem(item._id);
            }}
          />}
          <div>
            <p className="accordion-title">{item.edt} - {formatValue(item.name)}</p>
          </div>
        </div>
        <div>
          <p className="accordion-label">Data de Início</p>
          <p className="accordion-title">{isNaN(new Date(item.startDate)) ? "" : new Date(item.startDate).toLocaleDateString('pt-BR')}</p>
        </div>
        <div>
          <p className="accordion-label">Data de Término</p>
          <p className="accordion-title">{isNaN(new Date(item.endDate)) ? "" : new Date(item.endDate).toLocaleDateString('pt-BR')}</p>
        </div>
        <div>
          <p className="accordion-label">Responsável</p>
          <p className="accordion-title">{item.owner}</p>
        </div>
      </div>
      {item.items && openItems[item._id] && (
        <RenderItems items={item.items} level={level + 1} click={click} />
      )}
    </div>
  ));
};

const defaultItem = {
  edt: "",
  name: "",
  startDate: "",
  endDate: "",
  owner: "",
  _id: ''
}

const Timeline = () => {
  const { getTimelineItem, updateTimelineItem, bulkUpdate, deleteTimelineItem } = useBimActions();
  const { currentProject } = useBimContext();
  const { user } = useAuthContext()
  const [steps, setSteps] = useState([]);
  const [newStepInfo, setNewStepInfo] = useState(defaultItem);
  const [itemDetails, setItemDetails] = useState({})
  const [newItemDetails, setNewItemDetails] = useState(defaultItem)
  const [modalVisible, setModalVisible] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [data, setData] = useState([]); // Estado para armazenar os dados do arquivo
  const fileInputRef = useRef(null);

  // Função para lidar com o upload do arquivo
  const handleFileUpload = async (e) => {
    const file = e.target.files[0]; // Pega o primeiro arquivo selecionado
    if (!file) return;

    const reader = new FileReader(); // Cria um leitor de arquivos

    // Quando o arquivo for lido com sucesso
    reader.onload = async (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" }); // Lê o arquivo XLSX
      const worksheetName = workbook.SheetNames[0]; // Pega o nome da primeira planilha
      const worksheet = workbook.Sheets[worksheetName]; // Pega os dados da planilha
      const jsonData = XLSX.utils.sheet_to_json(worksheet); // Converte os dados para JSON
      const formatted = jsonData.map(i => {
        return {
          edt: i.EDT,
          name: i.Nome,
          startDate: convertDate(i.Início),
          endDate: convertDate(i.Término),
          owner: i.Responsável,
        }
      })
      setData(formatted); // Atualiza o estado com os dados do arquivo
      await bulkUpdate(formatted, currentProject._id, user.email)
      getItems();
      setData([])
    };

    reader.readAsBinaryString(file); // Lê o arquivo como uma string binária
  };

  useEffect(() => {
    getItems();
    setData([])
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
  }, [currentProject]);

  const getItems = async () => {
    const steps = await getTimelineItem(currentProject._id);
    setSteps(steps.res);
  };

  const handleSend = async () => {
    const info = { ...newStepInfo, edt: (+steps[steps.length - 1].edt + 1).toString().padStart(2, '0'), projectId: currentProject._id };
    const res = await updateTimelineItem(info);

    if (res.code === 200) {
      setSteps(prev => [...prev, { ...info, _id: res.newId, items: [] }]);
    }
  };

  const handleDetails = (e) => {
    const info = { ...e, projectId: currentProject._id }
    setItemDetails(info)
    setModalVisible(true)
  }

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "percentage" && Number(value) > 100 ? 100 : value;
    setItemDetails({ ...itemDetails, [name]: name === "percentage" ? Number(newValue) : newValue });
  };

  const updateNestedItem = (items, updatedItem) => {
    return items.map((item) => {
      if (item._id === updatedItem._id) {
        return { ...item, ...updatedItem };
      }

      if (item.items && item.items.length > 0) {
        const updatedItems = updateNestedItem(item.items, updatedItem);
        const totalPercentage = updatedItems.reduce((acc, child) => acc + (child.percentage || 0), 0);
        const averagePercentage = totalPercentage / updatedItems.length;

        return {
          ...item,
          items: updatedItems,
          percentage: averagePercentage,
        };
      }

      return item;
    });
  };

  const sendUpdate = async () => {
    delete itemDetails.items
    const res = await updateTimelineItem(itemDetails);

    if (res.code === 200) {
      setSteps((prevSteps) => updateNestedItem(prevSteps, itemDetails));
    }
  };

  const handleNewDetailsChange = (e) => {
    const { name, value } = e.target
    setNewItemDetails({ ...newItemDetails, [name]: value })
  }

  const insertNested = (items, newItem, parentId) => {
    return items.map((item) => {
      if (item._id === parentId) {
        return {
          ...item,
          items: [...item.items, { ...newItem, items: [] }],
        };
      }

      if (item.items && item.items.length > 0) {
        return {
          ...item,
          items: insertNested(item.items, newItem, parentId),
        };
      }

      return item;
    });
  }

  const sendNewItem = async () => {
    const e = { ...newItemDetails, edt: `${itemDetails.edt}.${(itemDetails.items.length + 1).toString().padStart(2, '0')}`, projectId: currentProject._id }
    const res = await updateTimelineItem(e)

    if (res.code === 200) {
      setSteps(prev => insertNested(prev, { ...e, _id: res.newId }, itemDetails._id))
    }
  }

  const handleDelete = async () => {
    await deleteTimelineItem(itemDetails)
    const deleteNestedItem = (items, itemId) => {
      return items.filter((item) => item._id !== itemId).map((item) => {
        if (item.items && item.items.length > 0) {
          return {
            ...item,
            items: deleteNestedItem(item.items, itemId),
          };
        }
        return item;
      });
    };
    setSteps((prevSteps) => deleteNestedItem(prevSteps, itemDetails._id));
    setConfirmDelete(!confirmDelete);
    setModalVisible(false)
  }

  const handleConfirmDelete = () => {
    setConfirmDelete(!confirmDelete);
  };

  return (
    <div className="costs">
      {modalVisible && (
        <Modal close={() => setModalVisible(false)}>
          <h2>Editar {itemDetails.edt} - {itemDetails.name}</h2>
          {detailsFields(handleDetailsChange, itemDetails, sendUpdate, handleDelete, confirmDelete, handleConfirmDelete)}
          {itemDetails.type !== 'subverification' && (
            <>
              <h2>Novo Item</h2>
              {detailsFields(handleNewDetailsChange, newItemDetails, sendNewItem, false, confirmDelete, handleConfirmDelete)}
            </>
          )}
        </Modal>
      )}
      {currentProject.name ? <>
        <h2>Atualização por .xlsx</h2>
        <div className="grid columns-2-tablet gap-16 align-center">
          <div>
            <span>Enviar novo arquivo: <Tooltip message={"Enviar um arquivo substituirá TODOS os dados existentes de Cronograma"} /></span>
            <input ref={fileInputRef} type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
          </div>
          {currentProject.lastTimelineUpdate &&
            <div>
              <p>
                Última atualização feita por {currentProject.lastTimelineUpdate.user} em {new Date(currentProject.lastTimelineUpdate.date).toLocaleTimeString('pt-BR')}
              </p>
            </div>
          }
        </div>
        <div>
          {steps.length > 0 &&
            <RenderItems items={steps} level={0} click={handleDetails} />
          }
          <div className={`grid columns-2-tablet checklist-row`}>
            <div>
              <p className="accordion-label">Novo item</p>
              <input
                value={newStepInfo.name}
                onChange={(e) =>
                  setNewStepInfo((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <button onClick={handleSend} className="button button-accent justify-self-center">
              Enviar
            </button>
          </div>
        </div>
      </> : <>
        <h2>Selecione um projeto</h2>
      </>
      }
    </div>
  );
};

export default Timeline;