import Modal from "@components/Modal";
import { useBimActions, useBimContext } from "@contexts/bim";
import { translateStatus } from "@utils/translateStatus";
import { useEffect, useState } from "react";

const formatValue = (value) => (value ? value : "-");
const getName = (e) => {
  let name = ''
  switch (e) {
    case 'step':
      name = 'Fase'
      break;
    case 'substep':
      name = 'Sub-etapa'
      break;
    case 'activity':
      name = 'Atividade'
      break;
    case 'subactivity':
      name = 'Subatividade'
      break;

    default:
      break;
  }
  return name
}

const detailsFields = (handleDetailsChange, itemDetails, sendUpdate, deleteButton, confirmDelete, handleConfirmDelete, hasChildren) => {
  return <div className="grid columns-2 gap-8 margin-bottom-16">
    <div>
      <p className="accordion-label">Nome</p>
      <input onChange={handleDetailsChange} name="name" value={itemDetails.name} />
    </div>
    <div>
      <p className="accordion-label">Status</p>
      <select onChange={handleDetailsChange} value={itemDetails.status} name="status">
        <option value="" disabled>Selecione uma opção</option>
        <option value="not-started">Não iniciado</option>
        <option value="ongoing">Em andamento</option>
        <option value="on-hold">Paralisado</option>
        <option value="done">Concluído</option>
      </select>
    </div>
    <div>
      <p className="accordion-label">Porcentagem</p>
      <input disabled={hasChildren} onChange={handleDetailsChange} type="number" name="percentage" value={itemDetails.percentage} />
    </div>
    <div className="column-span-2">
      <p className="accordion-label">Observações</p>
      <input onChange={handleDetailsChange} type="textarea" name="observation" value={itemDetails.observation} />
    </div>
    <button className="button button-accent" onClick={sendUpdate}>Enviar</button>
    {deleteButton && <>
      {!confirmDelete ? <button className="button button-error justify-self-end" onClick={handleConfirmDelete}>DELETAR</button> : <div>
        <button className="button button-red" onClick={deleteButton}>Deletar</button>
        <button className="button button-accent" onClick={handleConfirmDelete}>Cancelar</button>
      </div >}
    </>
    }
  </div>

}

const RenderItems = ({ items, level, click }) => {
  const [openItems, setOpenItems] = useState({});

  const toggleItem = (id) => {
    setOpenItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const marginLeft = `${level * 20}px`;
  return items.map((item) => (
    <div key={item._id}>
      <div
        style={{ marginLeft }}
        onClick={() => click(item)}
        className={`grid columns-7-tablet checklist-row ${item.observation !== '' ? 'has-obs' : ''
          }`}>
        <div className="flex column-span-5">
          {item.items?.length > 0 && <button
            className={`accordion-toggle ${openItems[item._id] ? 'active' : ''}`}
            onClick={(e) => {
              e.stopPropagation(); // Impede que o clique no botão acione o clique no pai
              toggleItem(item._id);
            }}
          />}
          <div>
            <p className="accordion-label">{getName(item.type)}</p>
            <p className="accordion-title">{formatValue(item.name)}</p>
          </div>
        </div>
        <div>
          <p className="accordion-label">Status</p>
          <p className="accordion-title">{translateStatus(item.status)}</p>
        </div>
        <div>
          <p className="accordion-label">Porcentagem</p>
          <p className="accordion-title">{+item.percentage.toFixed(2)}%</p>
        </div>
      </div>
      {item.items && openItems[item._id] && (
        <RenderItems items={item.items} level={level + 1} click={click} />
      )}    </div>
  ));
};

const defaultItem = {
  parentId: "",
  type: "",
  name: "",
  status: "",
  percentage: 0,
  observation: "",
  projectId: '',
  _id: ''
}

const Deliverables = () => {
  const { getDeliverablesItems, updateDeliverablesItem, getProjects, getDeliverablesSteps, copyDeliverablesStep, deleteChecklistItem } = useBimActions();
  const { currentProject } = useBimContext();
  const [steps, setSteps] = useState([]);
  const [newStepInfo, setNewStepInfo] = useState(defaultItem);
  const [itemDetails, setItemDetails] = useState({})
  const [newItemDetails, setNewItemDetails] = useState(defaultItem)
  const [modalVisible, setModalVisible] = useState(false)
  const [projects, setProjects] = useState([])
  const [projectToCopy, setProjectToCopy] = useState({})
  const [stepsToCopyList, setStepsToCopyList] = useState([])
  const [stepToCopy, setStepToCopy] = useState({})
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    getItems();
  }, [currentProject, steps.length]);

  useEffect(() => {
    findProjects()
  }, [])

  const findProjects = async () => {
    const projs = await getProjects('all')
    setProjects(projs.res)
    setProjectToCopy(projs.res[0])
  }

  const getItems = async () => {

    const steps = await getDeliverablesItems(currentProject._id, "step");
    setSteps(steps.res);
  };

  const findSteps = async () => {
    const steps = await getDeliverablesSteps(projectToCopy._id)
    setStepsToCopyList(steps.res)
    setStepToCopy(steps.res[0])
  }

  useEffect(() => {
    findSteps()
  }, [projectToCopy])


  const handleSend = async () => {
    const info = { ...newStepInfo, type: 'step', projectId: currentProject._id };
    const res = await updateDeliverablesItem(info);
    if (res.code === 200) {
      setSteps(prev => [...prev, { ...info, _id: res.newId, items: [], parentId: '' }]);
    }
  };

  const handleDetails = (e) => {
    const info = { ...e, projectId: currentProject._id }
    setItemDetails(info)
    setModalVisible(true)
  }

  const handleDetailsChange = (e) => {
    const { name, value } = e.target;
    const newValue = name === "percentage" && Number(value) > 100 ? 100 : value;
    setItemDetails({ ...itemDetails, [name]: name === 'percentage' ? +newValue : newValue });
  };

  const updateNestedItem = (items, updatedItem) => {
    return items.map((item) => {
      if (item._id === updatedItem._id) {
        return { ...item, ...updatedItem };
      }

      if (item.items && item.items?.length > 0) {
        const updatedItems = updateNestedItem(item.items, updatedItem);
        const totalPercentage = updatedItems.reduce((acc, child) => acc + (child.percentage || 0), 0);
        const averagePercentage = totalPercentage / updatedItems?.length;

        return {
          ...item,
          items: updatedItems,
          percentage: averagePercentage,
        };
      }

      return item;
    });
  };

  const sendUpdate = async () => {
    delete itemDetails.items
    const res = await updateDeliverablesItem(itemDetails);

    if (res.code === 200) {
      setSteps((prevSteps) => updateNestedItem(prevSteps, itemDetails));
    }
  };

  const handleNewDetailsChange = (e) => {
    const { name, value } = e.target
    setNewItemDetails({ ...newItemDetails, [name]: value })
  }

  const checkStep = () => {
    const { type } = itemDetails
    switch (type) {
      case 'step':
        return 'substep'
      case 'substep':
        return 'activity'
      case 'activity':
        return 'subactivity'
      default:
        break;
    }
  }

  const insertNested = (items, newItem) => {
    return items.map((item) => {
      if (item._id === newItem.parentId) {
        return {
          ...item,
          items: [...item.items, { ...newItem, items: [] }],
        };
      }

      if (item.items && item.items?.length > 0) {
        return {
          ...item,
          items: insertNested(item.items, newItem),
        };
      }
      return item;
    });
  }

  const sendNewItem = async () => {
    const e = { ...newItemDetails, parentId: itemDetails._id, _id: '', type: checkStep(), projectId: currentProject._id }
    const res = await updateDeliverablesItem(e)

    if (res.code === 200) {
      setSteps(prev => insertNested(prev, { ...e, _id: res.newId }))
    }
  }

  const handleCopy = async () => {
    const res = await copyDeliverablesStep({ step: stepToCopy, targetProjectId: currentProject._id })
    if (res.code === 200) {
      setSteps(prev => [...prev, { ...stepToCopy, _id: res.newId, parentId: '', projectId: currentProject._id }])
    }
  }

  const handleDelete = async () => {
    await deleteChecklistItem(itemDetails._id, 'deliverables')
    const deleteNestedItem = (items, itemId) => {
      return items.filter((item) => item._id !== itemId).map((item) => {
        if (item.items && item.items?.length > 0) {
          return {
            ...item,
            items: deleteNestedItem(item.items, itemId),
          };
        }
        return item;
      });
    };
    setSteps((prevSteps) => deleteNestedItem(prevSteps, itemDetails._id));
    setConfirmDelete(!confirmDelete);
    setModalVisible(false)
  }

  const handleConfirmDelete = () => {
    setConfirmDelete(!confirmDelete);
  };

  const hasChildren = itemDetails.items && itemDetails.items?.length > 0;

  return (
    <div className="costs">
      {modalVisible && <Modal close={() => setModalVisible(false)}>
        <h2>Editar {itemDetails.name} - {itemDetails.type}</h2>
        {detailsFields(handleDetailsChange, itemDetails, sendUpdate, handleDelete, confirmDelete, handleConfirmDelete, hasChildren)}
        {itemDetails.type !== 'subactivity' &&
          <>
            <h2>Novo Item</h2>
            {detailsFields(handleNewDetailsChange, newItemDetails, sendNewItem)}
          </>
        }
      </Modal>}
      {currentProject.name ? <>
        <h2>Copiar Fase</h2>
        <div className="flex gap-16">
          <select onChange={(e) => setProjectToCopy(projects[e.target.value])} >
            {projects?.map((i, idx) => {
              return <option key={i.name} value={idx}>{i.name}</option>
            })}
          </select>
          <select onChange={(e) => setStepToCopy(stepsToCopyList[e.target.value])} >
            {stepsToCopyList?.map((i, idx) => {
              return <option key={i.name} value={idx}>{i.name}</option>
            })}
          </select>
          <button onClick={handleCopy} className="button button-accent">
            Copiar
          </button>

        </div>
        <div>
          {steps.length > 0 &&
            <RenderItems items={steps} level={0} click={handleDetails} />
          }
          <div className={`grid columns-2-tablet checklist-row`}>
            <div>
              <p className="accordion-label">Novo item</p>
              <input
                value={newStepInfo.name}
                onChange={(e) =>
                  setNewStepInfo((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <button onClick={handleSend} className="button button-accent justify-self-center">
              Enviar
            </button>
          </div>
        </div>
      </> : <h2>Selecione um projeto</h2>}
    </div>
  );
};

export default Deliverables;