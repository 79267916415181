import { useAuthContext } from "@contexts/auth";

export const permissionRoles = [{
  name: "Admin",
  value: "admin",
}, {
  name: "Usuário Ampli",
  value: "ampli",
}, {
  name: "Usuário BIM",
  value: "bim",
}, {
  name: "Usuário Custos",
  value: "costs",
}, {
  name: 'Admin de Construtora',
  value: 'companyAdmin',
}, {
  name: 'Admin BIM',
  value: 'bimAdmin',
}]

export const checkRoles = (roleNeeded) => {
  const { user } = useAuthContext();
  const rolesCheck = user && Array.isArray(user.roles) ? user.roles : [];
  if (rolesCheck.includes(permissionsTokens.admin)) return true;
  return rolesCheck.includes(permissionsTokens[roleNeeded]);
};
