import {
  useDashboardUIActions,
} from "@contexts/dashboardUI";

export default (state, dispatch) => {

  const updateIndex = async (item) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/updateIndex`, {
      method: "POST",
      body: JSON.stringify({ name: item })
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getOptions = async () => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/getOptions`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  };


  const updateOption = async (option) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/updateOption`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(option),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        console.error("Erro ao atualizar a opção, status:", response.status, errorDetails);
        throw new Error("Erro ao atualizar a opção");
      }

      const { message } = await response.json();
      setIsLoading(false);

      return { code: response.status, message };
    } catch (error) {
      console.error("Erro ao atualizar a opção:", error);
      setIsLoading(false);
      return { code: 500, message: "Erro ao atualizar a opção" };
    }
  };

  const addOptionAction = async (newOption) => {
    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/updateOption`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newOption),
      });

      const data = await response.json();

      if (response.ok) {
        return { success: true, option: data.option };
      } else {
        return { success: false, message: data.message || "Erro desconhecido" };
      }
    } catch (error) {
      console.error("Erro ao adicionar a opção:", error);
      return { success: false, message: "Erro ao adicionar a opção." };
    }
  };

  const updateProject = async (building) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/updateProject`, {
      method: "POST",
      body: JSON.stringify(building)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getProjects = async (bimIds) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    let bimIdsString = bimIds
    if (Array.isArray(bimIds)) bimIdsString = bimIds.join(',');
    const response = await fetch(`${process.env.SERVER_URL}/bim/getProjects?bimIds=${bimIdsString}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const selectProject = (project) => {
    dispatch({
      type: "SELECT_PROJECT",
      data: project
    })
  }

  const getChecklistItems = async (projectId, type = '', parentId = '') => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/getChecklistItem?projectId=${projectId}&type=${type}&=${parentId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const updateChecklistItem = async (params) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/updateChecklistItem`, {
      method: "POST",
      body: JSON.stringify(params)
    })
    const { message, newId } = await response.json()
    setIsLoading(false)
    return { code: response.status, message, newId }
  }

  const getChecklistSteps = async (projectId) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/getChecklistSteps?projectId=${projectId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const copyChecklistStep = async (body) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/copyChecklistStep`, {
      method: "POST",
      body: JSON.stringify(body)
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }


  const deleteChecklistItem = async (id, collection) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/deleteChecklistItem?itemId=${id}&collectionName=${collection}`, {
      method: "DELETE"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const getDeliverablesItems = async (projectId, type = '', parentId = '') => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/getDeliverablesItem?projectId=${projectId}&type=${type}&=${parentId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const updateDeliverablesItem = async (params) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/updateDeliverablesItem`, {
      method: "POST",
      body: JSON.stringify(params)
    })
    const { message, newId } = await response.json()
    setIsLoading(false)
    return { code: response.status, message, newId }
  }

  const getDeliverablesSteps = async (projectId) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/getDeliverablesSteps?projectId=${projectId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const copyDeliverablesStep = async (body) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/copyDeliverablesStep`, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const getTimelineItem = async (projectId) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/getTimelineItem?projectId=${projectId}`, {
      method: "GET"
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const updateTimelineItem = async (params) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/updateTimelineItem`, {
      method: "POST",
      body: JSON.stringify(params)
    })
    const { message, newId } = await response.json()
    setIsLoading(false)
    return { code: response.status, message, newId }
  }

  const deleteTimelineItem = async (item) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/deleteTimelineItem`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ item })
    }).then(res => {
      return res.json()
    })
    setIsLoading(false)
    return response
  }

  const getClassifications = async () => {
    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/getClassifications`, {
        method: "GET",
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        console.error("Erro ao buscar opções, status:", response.status, errorDetails);
        throw new Error("Erro ao buscar opções");
      }

      const data = await response.json();

      // Acesse a chave 'res' e verifique se é um array
      if (data && Array.isArray(data.res)) {
        return data.res;  // Retorna o array dentro de 'res'
      } else {
        console.error("Formato de dados inesperado:", data);
        return [];  // Retorna lista vazia caso o formato não seja o esperado
      }
    } catch (error) {
      console.error("Erro ao buscar opções:", error);
      return [];  // Retorna lista vazia se houver erro
    }
  };





  const updateClassification = async (Classification) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/updateClassification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Classification),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        console.error("Erro ao atualizar a opção, status:", response.status, errorDetails);
        throw new Error("Erro ao atualizar a opção");
      }

      const { message } = await response.json();
      setIsLoading(false);

      return { code: response.status, message };
    } catch (error) {
      console.error("Erro ao atualizar a opção:", error);
      setIsLoading(false);
      return { code: 500, message: "Erro ao atualizar a opção" };
    }
  };

  const addClassificationAction = async (newClassification) => {
    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/updateClassification`, {
        method: "POST", // Criando uma nova classificação, POST é adequado aqui
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newClassification),
      });

      const data = await response.json();

      if (response.ok) {
        return { success: true, classification: data.classification };
      } else {
        return { success: false, message: data.message || "Erro desconhecido ao adicionar classificação" };
      }
    } catch (error) {
      console.error("Erro ao adicionar a classificação:", error);
      return { success: false, message: "Erro ao adicionar a classificação." };
    }
  };

  const bulkUpdate = async (itens, projectId, email) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true)
    const response = await fetch(`${process.env.SERVER_URL}/bim/bulkTimelineUpdate`, {
      method: "POST",
      body: JSON.stringify({
        itens,
        projectId,
        email
      })
    })
    const { message } = await response.json()
    setIsLoading(false)
    return { code: response.status, message }
  }

  const getLessons = async () => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/getLessons`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Erro ao buscar lições aprendidas");
      }

      const data = await response.json();
      setIsLoading(false);

      return data;
    } catch (error) {
      console.error("Erro em getLessons:", error);
      setIsLoading(false);
      throw error;
    }
  };

  const updateLesson = async (updateLesson) => {
    const { setIsLoading } = useDashboardUIActions();
    setIsLoading(true);

    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/updateLesson`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateLesson),
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        console.error("Erro ao atualizar a opção, status:", response.status, errorDetails);
        throw new Error("Erro ao atualizar a opção");
      }

      const { message } = await response.json();
      setIsLoading(false);

      return { code: response.status, message };
    } catch (error) {
      console.error("Erro ao atualizar a opção:", error);
      setIsLoading(false);
      return { code: 500, message: "Erro ao atualizar a opção" };
    }
  };

  const deleteLesson = async (lessonId) => {
    try {
      const response = await fetch(`${process.env.SERVER_URL}/bim/deleteLessons/${lessonId}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Erro ao excluir a lição aprendida");
      }

      return await response.json();
    } catch (error) {
      console.error("Erro em deleteLesson:", error);
      throw error;
    }
  };


  return {
    updateIndex,
    getClassifications,
    updateClassification,
    addClassificationAction,
    updateProject,
    getOptions,
    addOptionAction,
    updateOption,
    getProjects,
    selectProject,
    getChecklistItems,
    updateChecklistItem,
    getChecklistSteps,
    copyChecklistStep,
    getDeliverablesItems,
    updateDeliverablesItem,
    getDeliverablesSteps,
    copyDeliverablesStep,
    bulkUpdate,
    getLessons,
    updateLesson,
    deleteLesson,
    deleteChecklistItem,
    getTimelineItem,
    updateTimelineItem,
    deleteTimelineItem
  };

};
